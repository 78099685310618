(function($) {
  $('.video__link').click(function (e) {
    e.preventDefault();
    if ($(this).hasClass('is-selected')) {
      $(this).removeClass('is-selected');
      $(this).next().removeClass('is-active');
      $(this).next().removeAttr("src", $(this).attr("href"));
    } else {
      $('.video__link').removeClass('is-selected');
      $(this).addClass('is-selected');
      $('.video__canvas').removeClass('is-active');
      $('.video__canvas').removeAttr("src", $(this).attr("href"));
      $(this).next().addClass('is-active');
      $(this).next().attr("src", $(this).attr("href"));
    }
    return false;
  });
  $('.accordion__content').hide();
  $('.accordion__trigger').click(function() {
    if ($(this).hasClass('is-selected')) {
      $(this).removeClass('is-selected');
      $(this).parent().next().slideUp();
    } else {
      $('.accordion__trigger').removeClass('is-selected');
      $(this).addClass('is-selected');
      $('.accordion__content').slideUp();
      $(this).parent().next().slideDown();
    }
    return false;
  });
  $('a[href="#top"]').click(function () {
    $('html, body').animate({
        scrollTop: 0
    }, 'slow');
    return false;
  });
  //MENU TRIGGER
  $(".trigger-menu, .page-overlay").click(function () {
    $("body").toggleClass("nav-is-active");
    return false;
  });
})(jQuery);
